<template>
  <div>
    <PageLoader />
    <v-layout wrap>
      <v-flex
        xs12
        style="color: #000; font-size: 20px; font-family: poppinsbold"
        pt-4
        pb-5
        >Contact Details
      </v-flex>
      <v-layout justify-start wrap>
        <v-flex>
          <v-layout wrap>
            <v-flex>
              <v-layout justify-center wrap v-if="contact.name">
                <v-flex xs12>
                  <span class="title1">Name</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.name }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-center wrap v-if="contact.email">
                <v-flex xs12>
                  <span class="title1">Email</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.email }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-center wrap v-if="contact.country">
                <v-flex xs12>
                  <span class="title1">Country</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.country }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-center wrap v-if="contact.state">
                <v-flex xs12>
                  <span class="title1">State</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.state }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-start py-5>
            <v-flex>
              <v-layout justify-start wrap v-if="contact.subject">
                <v-flex xs12>
                  <span class="title1">Subject</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.subject }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-start wrap v-if="contact.know">
                <v-flex xs12>
                  <span class="title1">Know</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.know }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex>
              <v-layout justify-start wrap v-if="contact.message">
                <v-flex xs12>
                  <span class="title1">Message</span>
                </v-flex>
                <v-flex xs12>
                  <span>{{ contact.message }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      contact: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      store.commit("appLoading", true);

      axios({
        method: "GET",
        url: "/contact/get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.contact,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.contact = response.data.data;
            store.commit("appLoading", false);
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          store.commit("appLoading", false);
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>